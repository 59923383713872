import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import ChatIcon from '@mui/icons-material/Chat';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import { Divider } from '@mui/material';
import ProductType from 'enums/ProductType';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const AVProductCardActionsSlide = ({
    productType,
    avProductType,
    onClick,
    isStimBasedSession
}) => {
    const { t } = useTranslation();
    const slideBasedAvSessionsEnabled = useFeatureIsOn('slide-based-sessions');
    const slideBasedTextSessionsEnabled = useFeatureIsOn('slide-based-sessions-text-sessions');

    return (
        <React.Fragment>
            <Divider />
            <CardActions>
                {slideBasedAvSessionsEnabled && <Button
                    startIcon={<VideocamOutlinedIcon style={{ fill: 'red' }} />}
                    onClick={() => {
                        onClick({
                            productType: avProductType,
                            isStimBasedSession: isStimBasedSession
                        });
                    }}>{ t('activityTypeSelector.buttons.videoChat') }</Button>}

                {slideBasedTextSessionsEnabled && <Button
                    startIcon={<ChatIcon style={{ fill: 'green' }} />}
                    onClick={() => {
                        onClick({
                            productType: productType,
                            isStimBasedSession: isStimBasedSession
                        });
                    }}
                >
                    { t('activityTypeSelector.buttons.textChat') }
                </Button>}
            </CardActions>
        </React.Fragment>
    );
};

AVProductCardActionsSlide.propTypes = {
    productType: PropTypes.instanceOf(ProductType),
    avProductType: PropTypes.instanceOf(ProductType),
    onClick: PropTypes.func,
    isStimBasedSession: PropTypes.bool
};

export default React.memo(AVProductCardActionsSlide);
