import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import ProductCard from './ProductCard';
import HatchBackground from './productCards/hatch.jpg';
import VideoDialBackground from './productCards/videodial.jpg';
import HeatmapBackground from './productCards/heatmap.jpg';
import BulletinBoardBackground from './productCards/bulletin-board.jpg';
import ProductType from '../../enums/ProductType';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import FocusGroupProductCard from './FocusGroupProductCard';
import IDIProductCard from './IDIProductCard';
import { clientUrl } from 'Config';
import StimSessionFocusGroupCard from './StimSessionFocusGroupCard';
import StimSessionIDICard from './StimSessionIDICard';
import { IfFeatureEnabled, useFeatureIsOn } from '@growthbook/growthbook-react';

const getProducts = (t) => ([
    {
        productType: ProductType.bulletinBoard,
        name: t('productTypes.text.bulletinBoard'),
        description: t('productDescriptions.text.bulletinBoard'),
        background: BulletinBoardBackground,
        iconColor: '#8BC34A',
        portalURL: null
    },
    {
        productType: ProductType.heatmapConceptTest,
        name: t('productTypes.text.heatmapConceptTest'),
        description: t('productDescriptions.text.heatmapConceptTest'),
        background: HeatmapBackground,
        iconColor: 'rgb(179, 76, 208)',
        portalURL: '/Heatmap/Create'
    },
    {
        productType: ProductType.videodialConceptTest,
        name: t('productTypes.text.videodialConceptTest'),
        description: t('productDescriptions.text.videodialConceptTest'),
        background: VideoDialBackground,
        iconColor: '#465C79',
        portalURL: '/Videodial/Create'
    },
    {
        productType: ProductType.brainstorm,
        name: t('productTypes.text.brainstorm'),
        description: t('productDescriptions.text.brainstorm'),
        background: HatchBackground,
        iconColor: '#935555',
        portalURL: '/Brainstorm/Create'
    }
]);

const ActivityTypeSelector = ({ onSelect }) => {
    const { t } = useTranslation();
    const products = useMemo(() => getProducts(t), [t]);
    const slideBasedAvSessionsEnabled = useFeatureIsOn('slide-based-sessions');
    const slideBasedTextSessionsEnabled = useFeatureIsOn('slide-based-sessions-text-sessions');
    const shouldShowLegacyCards = slideBasedAvSessionsEnabled || slideBasedTextSessionsEnabled;

    const handleSelectProduct = useCallback((product) => {
        if (product.productType === ProductType.bulletinBoard) {
            // Handle boards differently by redirecting user to the boards page
            window.open(clientUrl, '_blank');
        } else {
            onSelect(product);
        }
    }, [onSelect]);

    return (
        <React.Fragment>
            <AnimatePresence exitBeforeEnter>
                <motion.div
                    key="animation"
                    initial={{ opacity: 0, scaleY: 0 }}
                    animate={{ opacity: 1, scaleY: 1 }}
                    exit={{ opacity: 0, scaleY: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <Grid container spacing={3}>

                        <IfFeatureEnabled feature='stimulus-based-sessions'>
                            <Grid item key={'stim-focus-group-card'} xs={12} sm={6} md={4} lg={3}>
                                <StimSessionFocusGroupCard onClick={handleSelectProduct} />
                            </Grid>

                            <Grid item key={'stim-idi-card'} xs={12} sm={6} md={4} lg={3}>
                                <StimSessionIDICard onClick={handleSelectProduct} />
                            </Grid>
                        </IfFeatureEnabled>

                        { products.map(product =>
                            (<Grid item key={product.productType} xs={12} sm={6} md={4} lg={3}>
                                <ProductCard {...product} onClick={handleSelectProduct} />
                            </Grid>)
                        )}

                        { shouldShowLegacyCards &&
                            <React.Fragment>
                                <Grid item key={'focus-group-card'} xs={12} sm={6} md={4} lg={3}>
                                    <FocusGroupProductCard onClick={handleSelectProduct} />
                                </Grid>

                                <Grid item key={'idi-card'} xs={12} sm={6} md={4} lg={3}>
                                    <IDIProductCard onClick={handleSelectProduct} />
                                </Grid>
                            </React.Fragment>
                        }

                    </Grid>
                </motion.div>
            </AnimatePresence>
        </React.Fragment>
    );
};

ActivityTypeSelector.propTypes = {
    onSelect: PropTypes.func
};

export default ActivityTypeSelector;
